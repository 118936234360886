@import '../abstract/variables.scss';
.mdg-blue {
  color: $mdg-blue;
}

.mdg-green {
  color: $mdg-green;
}

.white {
  color: $white;
}

.black {
  color: $black;
}

.bg-grey {
  background-color: $grey;
}

.bg-mdg-blue {
  background-color: $mdg-blue;
}

.bg-mdg-green {
  background-color: $mdg-green;
}

.box-shadow {
  box-shadow: 0px 4px 2px $shadow;
}