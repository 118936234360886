// In this file you may add custom MS classes, 
// you can use these classes as you would bootsrap
// classes.

@import 'src/assets/css/abstract/variables.scss';

// MS Custom HTML Component Styling //
.button-teal {
    width: fit-content;
    padding: 0.75rem 1.25rem;
    border-radius: 2rem;
    border-style: none;
    color: $white;

    a {
        text-decoration: none;
        color: $white;
    }

    &:hover {
        background-color: $ms-secondary-default;
        color: $black;
        text-decoration: none;
        box-shadow: 0px 4px 4px $shadow;

        a {
            color: $black;
        }
    }
}

.button-green {
    width: fit-content;
    padding: 0.75rem 1.25rem;
    border-radius: 2rem;
    border-style: none;

    &:hover {
        background-color: $ms-primary-default;
        color: $white;
        text-decoration: none;
        box-shadow: 0px 4px 4px $shadow;
    }
}

.fake-button {
    width: fit-content;
    padding: 0.75rem 1.25rem;
    border-radius: 2rem;
    border-style: none;
}


// MS Custom Classes // 

.ms-minheight {
    min-height: 735px;
  }
  

.dropdown-menu {
    margin-left: -500;
}

.form-control {
    border-color: #B3B3B3;
  }
  
.thumbnail {
    display: block;
    padding: 4px;
    margin-bottom: 51px;
    line-height: 1.42857143;
    background-color: #ffffff;
    border: 2px solid #95A5A6;
    border-radius: 4px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip-word-wrap {
    line-height: normal;
    text-align: left;
    white-space: normal;
    max-width: 300px;
    word-wrap: break-word;
}

.pdf-download {
    font-size: 14px;
}

.pdf-download:hover {
    cursor: pointer;
}