// In this file you may add custom MS color classes, 
// you can use these classes as you would bootsrap
// classes.

@import 'src/assets/css/abstract/variables.scss';

// Font Color Classes //
.font-teal {
    color: $ms-primary-default;
}

.font-green {
    color: $ms-secondary-default;
}

.font-white {
    color: $white;
}


// Background color Classes //
.bg-teal {
    background-color: $ms-primary-default;
}

.bg-green {
    background-color: $ms-secondary-default;
}

.bg-white {
    background-color: $white;
}

.ms-warning {
    background-color: $warning-default !important;
  }
  
.ms-info {
    background-color: $ms-secondary-default !important;
}
 
.bg-ms-light-grey {
    background-color: $ms-light-grey;
}


// Badges //
.badge-ms-info {
    color:$white;
    background-color: $ms-secondary-default;
}

a.badge-ms-info:focus,
a.badge-ms-info:hover {
    color: $white;
    background-color: $ms-secondary-default;
}

a.badge-ms-info.focus,a.badge-ms-info:focus {
    outline:0;
    box-shadow:0 0 0 .2rem rgba(255,193,7,.5)
}

.badge-ms-light {
    color: $white;
    background-color: $ms-light-grey;
} 

a.badge-ms-info:focus,
a.badge-ms-info:hover {
    color: $white;
    background-color: $ms-light-grey;
}

a.badge-ms-info.focus,a.badge-ms-info:focus {
    outline:0;
    box-shadow:0 0 0 .2rem rgba(255,193,7,.5)
}

.badge-ms-default { 
    color: $white;
    background-color: $ms-primary-default;
}

a.badge-ms-info:focus,
a.badge-ms-info:hover {
    color: $white;
    background-color: $ms-primary-default;
}

a.badge-ms-info.focus,
a.badge-ms-info:focus {
    outline:0;
    box-shadow:0 0 0 .2rem rgba(255,193,7,.5);
}

.badge-ms-warning {
    color: $white;
    background-color: $warning-default;
}

a.badge-ms-info:focus,
a.badge-ms-info:hover {
    color: $white;
    background-color: $warning-default;
}

a.badge-ms-info.focus,a.badge-ms-info:focus {
    outline:0;
    box-shadow:0 0 0 .2rem rgba(255,193,7,.5);
}

