* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.bar {
  content: "";
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;
}

.bar:nth-child(1) {
  background-color: #da4733;
  animation: loading 3s linear infinite;
}

.bar:nth-child(2) {
  background-color: #3b78e7;
  animation: loading 3s linear 1s infinite;
}

.bar:nth-child(3) {
  background-color: #fdba2c;
  animation: loading 3s linear 2s infinite;
}

@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}
.dash-heading {
  text-align: center;
  white-space: pre;
  font-size: 28px;
  font-family: "Segoe UI Light", "Helvetica Neue Light", "Segoe UI", "Helvetica Neue", "Trebuchet MS", Verdana, sans-serif;
  font-weight: 200;
  cursor: default;
}

.dash-sub-heading {
  text-align: center;
  white-space: pre;
  font-size: 20px;
  font-family: "Segoe UI Light", "Helvetica Neue Light", "Segoe UI", "Helvetica Neue", "Trebuchet MS", Verdana, sans-serif;
  font-weight: 200;
  cursor: default;
}

.chatlio-title-bar.chatlio-title-bar-button.chatlio-bottom-right {
  background-color: #023866 !important;
}

.company-logo-side {
  max-height: 20px;
}

.dx-icon-offer {
  content: url("/assets/icons/component-icons/light-bid-icon.svg");
}

.dx-icon-offerdark {
  content: url("/assets/icons/component-icons/bid-icon.svg");
}

.dx-icon-pharmacy {
  font: 14px/1 DXIcons;
}

.dx-icon-pharmacydark {
  content: url("/assets/icons/component-icons/pharmacy-icon.svg");
}

.dx-icon-catalogdark {
  content: url("/assets/icons/component-icons/catalog-icon.svg");
}

.dx-icon-ms-logo-black {
  content: url("/assets/img/logos/ms_logo_black.svg");
}

.dx-icon-medigi-catalog {
  content: url("/assets/img/logos/medigi_icon.svg");
}

.dx-icon-503b-catalog {
  content: url("/assets/img/logos/503B_icon.svg");
}

.dx-icon-medshorts-catalog {
  content: url("/assets/img/logos/medshorts_icon.svg");
}

.dx-icon-search-catalog {
  content: url("/assets/icons//component-icons/mdg_search_tab_icon.svg");
}

.dx-icon-medigi {
  background-size: 50px;
  background: url("/assets/img/medigi-logo-short.png") no-repeat;
  background-position-x: 50% !important;
  filter: invert(0.9);
}

.dx-icon-truck {
  content: url("/assets/icons/component-icons/truck.svg");
}

.dx-icon-rfp {
  font: 14px/1 DXIcons;
}

.dx-icon-rfp:before {
  content: url("/assets/icons/component-icons/rfpv2.svg");
}

.dx-icon-rfp-dark {
  font: 14px/1 DXIcons;
}

.dx-icon-rfp-dark:before {
  content: url("/assets/icons/component-icons/rfpv2.svg");
  filter: invert(0.9);
}

.dx-icon-small-search {
  content: url("/assets/icons/component-icons/magnify-glass.svg");
}

.dx-icon-ashp-clr {
  content: url("/assets/icons/component-icons/ashp-icon-clr.svg");
}

.dx-icon-ashp {
  content: url("/assets/icons/component-icons/ashp-icon.svg");
}

.dx-icon-big-heart {
  content: url("/assets/icons/component-icons/Heart-Vector.svg");
}

.dx-icon-big-soft {
  content: url("/assets/icons/component-icons/warning-sign-vector.svg");
}

.dx-icon-big-RFP {
  content: url("/assets/icons/component-icons/RFP-Vector.svg");
}

.dx-icon-bell {
  font: 14px/1 DXIcons;
}

.dx-icon-bell::before {
  content: url("/assets/icons/component-icons/bell.svg");
}

.dx-icon-user-menu {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/profile.svg");
}

.dx-icon-analytics {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/analytics.svg");
}

.dx-icon-bids {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/bids.svg");
}

.dx-icon-carts {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/cart.svg");
}

.dx-icon-catalogs {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/catalogs.svg");
}

.dx-icon-collapse {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/collapse.svg");
}

.dx-icon-companies {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/companies.svg");
}

.dx-icon-dashboard {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/dashboard.svg");
}

.dx-icon-data {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/data.svg");
}

.dx-icon-direct {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/direct.svg");
}

.dx-icon-newsroom {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/frame-icon.svg");
}

.dx-icon-gpt {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/gpt.svg");
}

.dx-icon-home {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/home.svg");
}

.dx-icon-listings {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/listings.svg");
}

.dx-icon-orders {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/orders.svg");
}

.dx-icon-pharmacies {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/pharmacies.svg");
}

.dx-icon-profile {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/profile.svg");
}

.dx-icon-reports {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/reports.svg");
}

.dx-icon-requests {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/requests.svg");
}

.dx-icon-rfps {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/rfps.svg");
}

.dx-icon-tasks {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/tasks.svg");
}

.dx-icon-user-logs {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/user-logs.svg");
}

.dx-icon-users {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/users.svg");
}

.dx-icon-chart-dark {
  content: url("/assets/icons/component-icons/chart-dark.svg");
}

.dx-icon-c2 {
  font: 14px/1px DXIcons;
  content: url("/assets/icons/header/c2.svg");
}

.mdg-blue {
  color: #01203A;
}

.mdg-green {
  color: #69C27A;
}

.white {
  color: #FFFFFF;
}

.black {
  color: #000000;
}

.bg-grey {
  background-color: #EBEBEB;
}

.bg-mdg-blue {
  background-color: #01203A;
}

.bg-mdg-green {
  background-color: #69C27A;
}

.box-shadow {
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
}

.button-teal {
  width: fit-content;
  padding: 0.75rem 1.25rem;
  border-radius: 2rem;
  border-style: none;
  color: #FFFFFF;
}
.button-teal a {
  text-decoration: none;
  color: #FFFFFF;
}
.button-teal:hover {
  background-color: #BADA55;
  color: #000000;
  text-decoration: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.button-teal:hover a {
  color: #000000;
}

.button-green {
  width: fit-content;
  padding: 0.75rem 1.25rem;
  border-radius: 2rem;
  border-style: none;
}
.button-green:hover {
  background-color: #36A8Ad;
  color: #FFFFFF;
  text-decoration: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.fake-button {
  width: fit-content;
  padding: 0.75rem 1.25rem;
  border-radius: 2rem;
  border-style: none;
}

.ms-minheight {
  min-height: 735px;
}

.dropdown-menu {
  margin-left: -500;
}

.form-control {
  border-color: #B3B3B3;
}

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 51px;
  line-height: 1.42857143;
  background-color: #ffffff;
  border: 2px solid #95A5A6;
  border-radius: 4px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-word-wrap {
  line-height: normal;
  text-align: left;
  white-space: normal;
  max-width: 300px;
  word-wrap: break-word;
}

.pdf-download {
  font-size: 14px;
}

.pdf-download:hover {
  cursor: pointer;
}

.font-teal {
  color: #36A8Ad;
}

.font-green {
  color: #BADA55;
}

.font-white {
  color: #FFFFFF;
}

.bg-teal {
  background-color: #36A8Ad;
}

.bg-green {
  background-color: #BADA55;
}

.bg-white {
  background-color: #FFFFFF;
}

.ms-warning {
  background-color: #FFCB1F !important;
}

.ms-info {
  background-color: #BADA55 !important;
}

.bg-ms-light-grey {
  background-color: #E9E9E9;
}

.badge-ms-info {
  color: #FFFFFF;
  background-color: #BADA55;
}

a.badge-ms-info:focus,
a.badge-ms-info:hover {
  color: #FFFFFF;
  background-color: #BADA55;
}

a.badge-ms-info.focus, a.badge-ms-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-ms-light {
  color: #FFFFFF;
  background-color: #E9E9E9;
}

a.badge-ms-info:focus,
a.badge-ms-info:hover {
  color: #FFFFFF;
  background-color: #E9E9E9;
}

a.badge-ms-info.focus, a.badge-ms-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-ms-default {
  color: #FFFFFF;
  background-color: #36A8Ad;
}

a.badge-ms-info:focus,
a.badge-ms-info:hover {
  color: #FFFFFF;
  background-color: #36A8Ad;
}

a.badge-ms-info.focus,
a.badge-ms-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-ms-warning {
  color: #FFFFFF;
  background-color: #FFCB1F;
}

a.badge-ms-info:focus,
a.badge-ms-info:hover {
  color: #FFFFFF;
  background-color: #FFCB1F;
}

a.badge-ms-info.focus, a.badge-ms-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.os-blue-800 {
  color: #024059;
}

.os-blue-700 {
  color: #025E73;
}

.os-blue-400 {
  color: #4E9FBF;
}

.os-blue-300 {
  color: #4EA4D9;
}

.os-blue-200 {
  color: #B3DAF2;
}

.white {
  color: #FFFFFF;
}

.black {
  color: #000000;
}

.bg-grey {
  background-color: #EBEBEB;
}

.bg-os-blue-300 {
  background-color: #4EA4D9;
}

.bg-os-blue-800 {
  background-color: #024059;
}

.box-shadow {
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
}

pre {
  border: 0px;
  background-color: white;
  white-space: pre-wrap;
  word-break: keep-all;
}

.dx-toast-warning {
  background-color: #FFECAD;
  border: 2px solid #FFCB1F;
  border-radius: 5px;
  color: #E0AC00;
}

.dx-toast-error {
  background-color: #FFC2C2;
  border: 2px solid #B30000;
  border-radius: 5px;
  color: #7A0000;
}

.dx-toast-success {
  background-color: #C2FFC2;
  border: 2px solid #006000;
  border-radius: 5px;
  color: #002900;
}

.dx-field-value.dx-attention {
  color: #B30000;
}

.dx-field-value.dx-attention::before {
  background-color: #B30000;
  color: #7A0000;
}

.dx-button-mode-contained.dx-button-danger {
  background-color: #B30000;
  border-color: transparent;
  color: #FFFFFF;
}

.dx-button-mode-contained.dx-button-danger.dx-state-hover {
  background-color: #7A0000;
  border-color: transparent;
}

.dx-button-mode-contained.dx-button-danger.dx-state-focused {
  background-color: #7A0000;
  border-color: transparent;
}

.dx-button-mode-contained.dx-button-danger.dx-state-active {
  background-color: #7A0000;
  border-color: transparent;
  color: #FFFFFF;
}

.dx-button-mode-outlined.dx-button-danger {
  background-color: transparent;
  border-color: #B30000;
  color: #B30000;
}

.dx-button-mode-outlined.dx-button-danger .dx-icon {
  color: #B30000;
}

.dx-button-mode-outlined.dx-button-danger.dx-state-focused,
.dx-button-mode-outlined.dx-button-danger.dx-state-hover {
  background-color: #B30000;
  border-color: #7A0000;
}

.dx-button-mode-outlined.dx-button-danger.dx-state-active {
  background-color: #B30000;
  border-color: #7A0000;
  color: #FFFFFF;
}

.dx-button-mode-text.dx-button-danger {
  background-color: transparent;
  border-color: transparent;
  color: #B30000;
}

.dx-button-mode-text.dx-button-danger .dx-icon {
  color: #B30000;
}

.dx-button-mode-text.dx-button-danger.dx-state-active {
  background-color: #B30000;
  border-color: transparent;
  color: #FFFFFF;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected).dx-button-danger {
  border-color: #B30000;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected).dx-button-danger,
.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected).dx-button-danger .dx-icon {
  color: #B30000;
}

.dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-item-selected.dx-button-danger,
.dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-item-selected.dx-button-danger .dx-icon,
.dx-buttongroup-item.dx-button.dx-button-mode-text.dx-item-selected.dx-button-danger,
.dx-buttongroup-item.dx-button.dx-button-mode-text.dx-item-selected.dx-button-danger .dx-icon {
  color: #B30000;
}

.dx-invalid.dx-state-focused .dx-checkbox-container .dx-checkbox-icon {
  border-color: #B30000;
}

.dx-invalid.dx-state-focused .dx-switch-container,
.dx-invalid.dx-state-hover .dx-switch-container {
  border-color: #B30000;
}

.dx-invalid.dx-state-focused .dx-switch-handle::before,
.dx-invalid.dx-state-hover .dx-switch-handle::before,
.dx-invalid.dx-switch-on-value .dx-switch-handle::before {
  background-color: #B30000;
}

.dx-validationsummary-item {
  color: #B30000;
}

.dx-invalid-message > .dx-overlay-content {
  color: #FFFFFF;
  background-color: #B30000;
  border-radius: 5px;
}

.dx-invalid.dx-texteditor.dx-editor-filled.dx-state-focused,
.dx-invalid.dx-texteditor.dx-editor-outlined.dx-state-focused,
.dx-invalid.dx-texteditor.dx-editor-underlined.dx-state-focused {
  border-color: #B30000;
}

.dx-invalid.dx-texteditor.dx-editor-filled.dx-show-invalid-badge .dx-texteditor-input-container::after,
.dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container::after,
.dx-invalid.dx-texteditor.dx-editor-underlined.dx-show-invalid-badge .dx-texteditor-input-container::after {
  pointer-events: none;
  font-weight: 700;
  position: absolute;
  top: 50%;
  margin-top: -9px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 13px;
  background-color: #B30000;
  color: #FFFFFF;
  content: "!";
  border-radius: 50%;
}

span.badge.badge-danger {
  background: #F84D55;
  color: #FFFFFF;
}

span.badge.badge-warning {
  background: #F3B636;
  color: #FFFFFF;
}

i.warningtip {
  color: #F3B636;
}