.dash-heading {
    text-align: center;
    white-space: pre;
    font-size: 28px;
    font-family: "Segoe UI Light", "Helvetica Neue Light", "Segoe UI",
      "Helvetica Neue", "Trebuchet MS", Verdana, sans-serif;
    font-weight: 200;
    cursor: default;
}
  
.dash-sub-heading {
    text-align: center;
    white-space: pre;
    font-size: 20px;
    font-family: "Segoe UI Light", "Helvetica Neue Light", "Segoe UI",
      "Helvetica Neue", "Trebuchet MS", Verdana, sans-serif;
    font-weight: 200;
    cursor: default;
}

.chatlio-title-bar.chatlio-title-bar-button.chatlio-bottom-right {
    background-color: #023866 !important;
  }

// #chatlio-header-avatar-image {
//     content:url('/assets/img/logos/medigi-logo-short.png');
// }
  
// .chatlio-message-avatar img{
//     content:url('/assets/img/logos/medigi-logo-short.png');
// }
  
// .chatlio-avatar img{
//     content:url('/assets/img/logos/medigi-logo-short.png');
// }

.company-logo-side {
    max-height: 20px;
}

.dx-icon-offer {
    content: url('/assets/icons/component-icons/light-bid-icon.svg');
  }
  
.dx-icon-offerdark {
  content: url('/assets/icons/component-icons/bid-icon.svg');
}
  
.dx-icon-pharmacy {
  font: 14px/1 DXIcons;
}
  
.dx-icon-pharmacydark {
  content: url('/assets/icons/component-icons/pharmacy-icon.svg');
}
  
.dx-icon-catalogdark {
  content: url('/assets/icons/component-icons/catalog-icon.svg');
}
  
.dx-icon-ms-logo-black{
    content: url('/assets/img/logos/ms_logo_black.svg');
}
  
.dx-icon-medigi-catalog {
    content: url('/assets/img/logos/medigi_icon.svg');
}
  
.dx-icon-503b-catalog {
    content: url('/assets/img/logos/503B_icon.svg');
}
  
.dx-icon-medshorts-catalog {
    content: url('/assets/img/logos/medshorts_icon.svg');
}
  
.dx-icon-search-catalog {
    content: url('/assets/icons//component-icons/mdg_search_tab_icon.svg');
}
  
.dx-icon-medigi {
    background-size: 50px;
    background: url('/assets/img/medigi-logo-short.png') no-repeat;
    background-position-x: 50% !important;
    filter: invert(0.9);
}
  
.dx-icon-truck {
    content: url('/assets/icons/component-icons/truck.svg');
}
  
.dx-icon-rfp {
    font: 14px/1 DXIcons;
}
  
.dx-icon-rfp:before {
    content: url('/assets/icons/component-icons/rfpv2.svg');
}
  
.dx-icon-rfp-dark {
    font: 14px/1 DXIcons;
}
  
.dx-icon-rfp-dark:before {
    content: url('/assets/icons/component-icons/rfpv2.svg');
    filter: invert(0.9);
}  

.dx-icon-small-search {
    content: url('/assets/icons/component-icons/magnify-glass.svg');
}


.dx-icon-ashp-clr {
    content: url('/assets/icons/component-icons/ashp-icon-clr.svg');
}

.dx-icon-ashp {
    content: url('/assets/icons/component-icons/ashp-icon.svg');
}

.dx-icon-big-heart {
    content: url('/assets/icons/component-icons/Heart-Vector.svg');
}

.dx-icon-big-soft {
    content: url('/assets/icons/component-icons/warning-sign-vector.svg');
}

.dx-icon-big-RFP {
    content: url('/assets/icons/component-icons/RFP-Vector.svg');
}

.dx-icon-bell {
    font: 14px/1 DXIcons;
}

.dx-icon-bell::before {
    content: url('/assets/icons/component-icons/bell.svg');
}

.dx-icon-user-menu {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/profile.svg');
}

// new icons
.dx-icon-analytics {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/analytics.svg');
}

.dx-icon-bids {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/bids.svg');
}

.dx-icon-carts {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/cart.svg');
}

.dx-icon-catalogs {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/catalogs.svg');
}

.dx-icon-collapse {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/collapse.svg');
}

.dx-icon-companies {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/companies.svg');
}

.dx-icon-dashboard {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/dashboard.svg');
}

.dx-icon-data {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/data.svg');
}

.dx-icon-direct {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/direct.svg');
}

.dx-icon-newsroom {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/frame-icon.svg');
}

.dx-icon-gpt {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/gpt.svg');
}

.dx-icon-home {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/home.svg');
}

.dx-icon-listings {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/listings.svg');
}

.dx-icon-orders {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/orders.svg');
}

.dx-icon-pharmacies {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/pharmacies.svg');
}

.dx-icon-profile {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/profile.svg');
}

.dx-icon-reports {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/reports.svg');
}

.dx-icon-requests {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/requests.svg');
}

.dx-icon-rfps {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/rfps.svg');
}

.dx-icon-tasks {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/tasks.svg');
}

.dx-icon-user-logs {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/user-logs.svg');
}

.dx-icon-users {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/users.svg');
}

.dx-icon-chart-dark {
    content: url('/assets/icons/component-icons/chart-dark.svg');
}

.dx-icon-c2 {
    font: 14px/1px DXIcons;
    content: url('/assets/icons/header/c2.svg');
}