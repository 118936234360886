.os-blue-800 {
    color: $os-blue-800;
}

.os-blue-700 {
    color: $os-blue-700;
}

.os-blue-400 {
    color: $os-blue-400;
}

.os-blue-300 {
    color: $os-blue-300;
}

.os-blue-200 {
    color: $os-blue-200;
}

.white {
    color: $white;
}

.black {
    color: $black;
}

.bg-grey {
    background-color: $grey;
}

.bg-os-blue-300 {
    background-color: $os-blue-300;
}

.bg-os-blue-800 {
    background-color: $os-blue-800;
}

.box-shadow {
    box-shadow: 0px 4px 2px $shadow;
}