//This file has DevExtreme components with custom platform color 
//variables, this is not all the classes. This is just a majority 
//of the main components we use.

@import "/src/assets/css/abstract/variables.scss";

// Toast Styles // 
.dx-toast-warning {
    background-color: $warning-light;
    border: 2px solid $warning-default;
    border-radius: 5px;
    color: $warning-dark;
}

.dx-toast-error {
    background-color: $danger-light;
    border: 2px solid $danger-default;
    border-radius: 5px;
    color: $danger-dark;
}

.dx-toast-success {
    background-color: $success-light;
    border: 2px solid $success-default;
    border-radius: 5px;
    color: $success-dark;
}

.dx-field-value.dx-attention {
  color: $danger-default;
}

.dx-field-value.dx-attention::before {
    background-color: $danger-default;
    color: $danger-dark;
}

.dx-button-mode-contained.dx-button-danger {
    background-color: $danger-default;
    border-color: transparent;
    color: $white;
}

.dx-button-mode-contained.dx-button-danger.dx-state-hover {
    background-color: $danger-dark;
    border-color: transparent;
}
  
.dx-button-mode-contained.dx-button-danger.dx-state-focused {
    background-color: $danger-dark;
    border-color: transparent;
}
  
.dx-button-mode-contained.dx-button-danger.dx-state-active {
    background-color: $danger-dark;
    border-color: transparent;
    color: $white;
}

.dx-button-mode-outlined.dx-button-danger {
    background-color: transparent;
    border-color: $danger-default;
    color: $danger-default;
}

.dx-button-mode-outlined.dx-button-danger .dx-icon {
    color: $danger-default;
}

.dx-button-mode-outlined.dx-button-danger.dx-state-focused,
.dx-button-mode-outlined.dx-button-danger.dx-state-hover {
    background-color: $danger-default;
    border-color: $danger-dark;
}
 
.dx-button-mode-outlined.dx-button-danger.dx-state-active {
    background-color: $danger-default;
    border-color: $danger-dark;
    color: $white;
}

.dx-button-mode-text.dx-button-danger {
    background-color: transparent;
    border-color: transparent;
    color: $danger-default;
}

.dx-button-mode-text.dx-button-danger .dx-icon {
    color: $danger-default;
}

.dx-button-mode-text.dx-button-danger.dx-state-active {
  background-color: $danger-default;
  border-color: transparent;
  color: $white;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected).dx-button-danger {
  border-color: $danger-default;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected).dx-button-danger,
.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected).dx-button-danger .dx-icon {
  color: $danger-default;
}

.dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-item-selected.dx-button-danger,
.dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-item-selected.dx-button-danger .dx-icon,
.dx-buttongroup-item.dx-button.dx-button-mode-text.dx-item-selected.dx-button-danger,
.dx-buttongroup-item.dx-button.dx-button-mode-text.dx-item-selected.dx-button-danger .dx-icon {
  color: $danger-default;
}

.dx-invalid.dx-state-focused .dx-checkbox-container .dx-checkbox-icon {
    border-color: $danger-default;
}

.dx-invalid.dx-state-focused .dx-switch-container,
.dx-invalid.dx-state-hover .dx-switch-container {
  border-color: $danger-default;
}

.dx-invalid.dx-state-focused .dx-switch-handle::before,
.dx-invalid.dx-state-hover .dx-switch-handle::before,
.dx-invalid.dx-switch-on-value .dx-switch-handle::before {
  background-color: $danger-default;
}

.dx-validationsummary-item {
    color: $danger-default;
  }
  .dx-invalid-message > .dx-overlay-content {
    color: $white;
    background-color: $danger-default;
    border-radius: 5px;
  }

.dx-invalid.dx-texteditor.dx-editor-filled.dx-state-focused,
.dx-invalid.dx-texteditor.dx-editor-outlined.dx-state-focused,
.dx-invalid.dx-texteditor.dx-editor-underlined.dx-state-focused {
  border-color: $danger-default;
}

.dx-invalid.dx-texteditor.dx-editor-filled.dx-show-invalid-badge .dx-texteditor-input-container::after,
.dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container::after,
.dx-invalid.dx-texteditor.dx-editor-underlined.dx-show-invalid-badge .dx-texteditor-input-container::after {
  pointer-events: none;
  font-weight: 700;
  position: absolute;
  top: 50%;
  margin-top: -9px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 13px;
  background-color: $danger-default;
  color: $white;
  content: "!";
  border-radius: 50%;
}

span.badge.badge-danger {
  background: #F84D55;
  color: $white;
}

span.badge.badge-warning {
  background: #F3B636;
  color: $white;
}

i.warningtip {
  color: #F3B636;
}