// Overall Colors //
$white: #FFFFFF;
$black: #000000;
$grey: #EBEBEB;

$success-default: #006000;
$success-dark: #002900;
$success-light: #C2FFC2;

$warning-default: #FFCB1F;
$warning-dark: #E0AC00;
$warning-light: #FFECAD;

$danger-default: #B30000;
$danger-dark: #7A0000;
$danger-light: #FFC2C2;

$shadow: rgba(0, 0, 0, .25);

// Medshorts Colors // 

// Default //
$ms-dark-blue: #171F21;
$ms-dark-teal: #29444a; 
$ms-light-grey: #E9E9E9;
$ms-medium-grey: #9FADB0;

// Primary //
$ms-primary-default: #36A8Ad;
$ms-primary-dark: #27787C;
$ms-primary-light: #73CED3;

// Secondary //
$ms-secondary-default: #BADA55;
$ms-secondary-dark: #96B828;
$ms-secondary-light: #D7E99B;

// Fonts //
$ms-font-grey: #858585;
$ms-field-label-clr: #71858A;

//Outsourcing Colors // 
$os-blue-800: #024059;
$os-blue-700: #025E73;
$os-blue-400: #4E9FBF;
$os-blue-300: #4EA4D9;
$os-blue-200: #B3DAF2;

// Medigi Colors // 
$mdg-blue: #01203A;
$mdg-green: #69C27A;

// General Variables // 
$side-panel-min-width: 60px;
$base-border-color: #ededed;


//New Theme Variables //
$mdg-nt-yellow: #DBF227;
$mdg-nt-yellow-dark: #B2C706;

$mdg-nt-primary-light: #9BC6FA;
$mdg-nt-primary: #73ACF0;
$mdg-nt-primary-dark: #2985DB;

$mdg-nt-secondary-light: #E1F14C;
$mdg-nt-secondary: #E1F245;
$mdg-nt-secondary-dark: #C5D726;

$mdg-nt-success-light: #5EDC76;
$mdg-nt-success: #85BF79;
$mdg-nt-success-dark: #49AD5B;

$mdg-nt-warning-light: #FBC350;
$mdg-nt-warning: #F3B636;
$mdg-nt-warning-dark: #DCA127;

$mdg-nt-danger-light: #FF666D;
$mdg-nt-danger: #F84D55;
$mdg-nt-danger-dark: #DD3A42;

$mdg-nt-info-light: #95E4E4;
$mdg-nt-info: #71C6C6;
$mdg-nt-info-dark: #52A8A8;

$mdg-nt-light: #ECECEC;
$mdg-nt-dark: #1E2834;